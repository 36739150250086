import React, { useState } from 'react';
import './LowStockTodoList.css';
import DashboardHeader from '../Admin/Dashboard/DashboardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const LowStockTodoList = () => {
  const [itemName, setItemName] = useState('');
  const [currentStock, setCurrentStock] = useState('');
  const [lowStockItems, setLowStockItems] = useState([]);

  const navigate = useNavigate();

  const handleAddItem = () => {
    if (itemName && currentStock) {
      const newItem = {
        itemName,
        currentStock: currentStock,
      };

      // Add the new item to the list
      setLowStockItems([...lowStockItems, newItem]);

      // Clear input fields
      setItemName('');
      setCurrentStock('');
    } else {
      alert('Please fill in all fields');
    }
  };

  const redirectBack = () => {
    navigate(-1);
  }

  const handleSendEmail = async () => {
    if (lowStockItems.length === 0) {
      alert('No items to send.');
      return;
    }

    try {
   
        const response = await fetch('https://apenterprise-server.onrender.com/send-low-stock-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ items: lowStockItems }),
      });

      const result = await response.json();
      if (response.ok) {
        alert('Email sent successfully');
        setLowStockItems([]); // Clear the list after sending the email
      } else {
        alert(`Failed to send email: ${result.message}`);
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <>
      <DashboardHeader />
      <div className="low-stock-todo-container">
        <div className="header-container">
          <FontAwesomeIcon onClick={redirectBack} icon={faArrowLeftLong} size="2xl" className="back-icon" />
          <h3 className="header-title">Order Item</h3>
        </div>
        <div className="form-group">
          <label>Item Name:</label>
          <input
            type="text"
            placeholder="Enter item name"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Required Stock:</label>
          <input
            type="text"
            placeholder="Enter current stock level"
            value={currentStock}
            onChange={(e) => setCurrentStock(e.target.value)}
          />
        </div>
        <button className="add-item-button" onClick={handleAddItem}>
          Add Item
        </button>

        <ul className="low-stock-list">
          {lowStockItems.map((item, index) => (
            <li key={index}>
              <strong>{item.itemName}</strong> - {item.currentStock}
            </li>
          ))}
        </ul>

        {lowStockItems.length > 0 && (
          <button className="send-email-button" onClick={handleSendEmail}>
            Send Email to Logistics
          </button>
        )}
      </div>
    </>
  );
};

export default LowStockTodoList;
