import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import DashboardHeader from '../Dashboard/DashboardHeader';
import { faCheckSquare, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditPayee = () => {

    const navigate = useNavigate();
    const [name,setName] = useState();
    const [designation,setDesignation] = useState();
    const [salary,setSalary] = useState();
    const [number,setNumber] = useState();
    const [address,setAddress] = useState();
    const [isEditing, setIsEditing] = useState({name:false,designation:false,salary:false,number:false,address:false});


    const {id} = useParams();

    const getPayeeDetails = async () => {
        try{
            const resp = await axios.get(`https://apenterprise-server.onrender.com/payee/get-payee/${id}`);
            setName(resp.data.name);
            setDesignation(resp.data.designation);
            setSalary(resp.data.salary);
            setNumber(resp.data.phoneNumber);
            setAddress(resp.data.address);
        } catch(err){
            console.log(err);
        }
    }


    const updatePayeeDetails = async () => {
        const result = window.confirm("Are you sure you want to update the details ?");
        if(result){
        try {
            const body = {
                name,
                designation,
                salary,
                address,
                phoneNumber: number
            }
            const resp = await axios.put(`https://apenterprise-server.onrender.com/payee/edit-payee/${id}`,body);
            toast.success('Successfully Edited the payee!', {
                position: "top-right",
                autoClose: 2200,
                onClose: () => navigate('/payroll')
            });
        } catch(err) {
            toast.error('Something went wrong! Try Again!', {
                position: "top-right",
                autoClose: 2200,
            });
            console.log(err);
        }
    }
    }


    const deletePayee = async () => {
        const result = window.confirm("Are you sure you want to update the details ?");
        if(result){
        try{
            const resp = await axios.delete(`https://apenterprise-server.onrender.com/payee/delete-payee/${id}`);
            toast.success('Successfully Deleted the payee!', {
                position: "top-right",
                autoClose: 1200,
                onClose: () => navigate('/payroll')
            });
        } catch(err) {
            toast.error('Something went wrong! Try Again!', {
                position: "top-right",
                autoClose: 1200,
            });
            console.log(err);
        }
    }
    }


    useEffect(()=> {
        getPayeeDetails();
    
    },[]);

    const toggleEdit = (field) => {
        setIsEditing(prevState => ({
            ...prevState,
            [field]: !prevState[field] 
        }));

    };


  return (
    <div>
    <DashboardHeader />
    <ToastContainer />
    <div className="container d-flex justify-content-center align-items-center">
      <div class="card w-75 mt-5">
        <div class="card-body d-flex flex-column justify-content-center align-items-center">
          <h5 class="card-title text-center mb-4">Edit Payee Details</h5>

          <div class="input-group mb-3">
  <span class="input-group-text" id="basic-addon1">Name</span>
  <input onChange={(e)=> setName(e.target.value)} disabled={!isEditing.name} value={name} type="text" class="form-control" placeholder="Enter Name" aria-label="Username" aria-describedby="basic-addon1"/>
  <span class="input-group-text"><FontAwesomeIcon className={!isEditing.name?'hover-edit': 'hover-success'}   icon={isEditing.name ? faCheckSquare : faPenToSquare} onClick={()=>toggleEdit('name')}/></span>
</div>

<div class="input-group mb-3">
  <span class="input-group-text" id="basic-addon1">Designation</span>
  <input  onChange={(e)=> setDesignation(e.target.value)} disabled={!isEditing.designation} type="text" value={designation} class="form-control" placeholder="Enter Designation" aria-label="Designation" aria-describedby="basic-addon1"/>
  <span class="input-group-text"><FontAwesomeIcon className={!isEditing.designation?'hover-edit': 'hover-success'}  icon={isEditing.designation ? faCheckSquare : faPenToSquare} onClick={()=>toggleEdit('designation')} /></span>
</div>

<div class="input-group mb-3">
  <span class="input-group-text" id="basic-addon1">Phone Number</span>
  <input  onChange={(e)=> setNumber(e.target.value)} disabled={!isEditing.number} type="text" value={number} class="form-control" placeholder="Enter Phone Number" aria-label="Number" aria-describedby="basic-addon1"/>
  <span class="input-group-text"><FontAwesomeIcon className={!isEditing.number?'hover-edit': 'hover-success'}  icon={isEditing.number ? faCheckSquare : faPenToSquare} onClick={()=>toggleEdit('number')} /></span>
</div>

<div class="input-group mb-3">
  <span class="input-group-text" id="basic-addon1">Address</span>
  <input  onChange={(e)=> setAddress(e.target.value)} disabled={!isEditing.address} type="text" value={address} class="form-control" placeholder="Enter Address" aria-label="Address" aria-describedby="basic-addon1"/>
  <span class="input-group-text"><FontAwesomeIcon className={!isEditing.address?'hover-edit': 'hover-success'}  icon={isEditing.address ? faCheckSquare : faPenToSquare} onClick={()=>toggleEdit('address')} /></span>
</div>

<div class="input-group mb-3">
  <span class="input-group-text">Salary Rs.</span>
  <input  onChange={(e)=> setSalary(e.target.value)} disabled={!isEditing.salary} value={salary} type="number" class="form-control" aria-label="Amount (to the nearest dollar)"/>
  <span class="input-group-text"><FontAwesomeIcon className={!isEditing.salary?'hover-edit': 'hover-success'}   icon={isEditing.salary ? faCheckSquare : faPenToSquare} onClick={()=>toggleEdit('salary')} /></span>
</div>


        <div className='d-flex justify-content-center'>
        <button onClick={updatePayeeDetails} className="btn btn-outline-success m-2">Update</button>
        <button onClick={deletePayee}  className="btn btn-danger m-2">Delete</button>
        </div>
       
        </div>
      </div>
    </div>
  </div>
  )
}

export default EditPayee