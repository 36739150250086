import React, { useEffect, useState } from 'react';
import UserContext from './UserContext';
import { auth } from './firebase';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const UserProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [userDetails,setUserDetails] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [isAdmin,setIsAdmin] = useState(false);
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthenticated(true);
        if(user.email === 'admin@apenterprise.com'){
          setIsAdmin(true)
        }
      } else {
        setAuthenticated(false);
        setIsAdmin(false);
        setError(null)
      }
    });

    return () => unsubscribe();
  }, []);


  useEffect(()=> {
    console.log('Authenticated:',authenticated)
  },[authenticated])



  const signIn = async (userData) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        userData.email,
        userData.password
      );
        setUserDetails(userCredential);
        setLoading(true);
        if (userData.email === 'admin@apenterprise.com') {
          setIsAdmin(true);
        } else {
          setIsAdmin(false); 
        }
        setTimeout(() => {
          setLoading(false); 
          setAuthenticated(true);
          if(userData.email==='karim@apenterprise.com'){
            navigate('/pricelist')
          }
          // else if(userData.email!=='admin@apenterprise.com'){
          //   navigate('/invoice')
            
          // }
          else{
            navigate('/admin-dashboard')
          }
          console.log('User signed in!');
        }, 3000);
    } catch (err) {
      setError(err.message);
      toast.error(err.message, {
        position: "top-right",
        autoClose: 2200,
    });
      setError(err);
      console.log(err);
    }
  };


  const logout = async () => {
    try {
      await signOut(auth);
      setAuthenticated(false);
      setIsAdmin(false);
      navigate('/login');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <UserContext.Provider value={{ authenticated,userDetails, signIn, logout, error,setError,isAdmin,loading }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
