import React, { useState } from 'react'
import DashboardHeader from '../Dashboard/DashboardHeader'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateCustomer = () => {

    const [companyName,setName] = useState();
    const [address,setAddress] = useState();

    const navigate = useNavigate();


        const createCustomer = async () => {
      try {
        const body = {
            companyName,
          address,
        }
        await axios.post('https://apenterprise-server.onrender.com/customer/add-member',body);
        toast.success('Successfully Added a Customer!', {
            position: "top-right",
            autoClose: 2200,
            onClose: () => navigate('/customers')
        });
       
      } catch(err){
        console.log(err);
      }
    }





  return (
    <div>
    <DashboardHeader />
    
    <div className="container d-flex justify-content-center align-items-center">
    <ToastContainer />
      <div class="card w-75 mt-5">
        <div class="card-body d-flex flex-column justify-content-center align-items-center">
          <h5 class="card-title text-center mb-4">Enter Customer Details</h5>

          <div class="input-group mb-3">
  <span class="input-group-text" id="basic-addon1">Name</span>
  <input onChange={(e)=> setName(e.target.value)} type="text" class="form-control" placeholder="Enter Name" aria-label="Username" aria-describedby="basic-addon1"/>
</div>

<div class="input-group mb-3">
  <span class="input-group-text" id="basic-addon1">Address</span>
  <textarea  onChange={(e)=> setAddress(e.target.value)} type="text" class="form-control" placeholder="Enter Office Address" aria-label="Designation" aria-describedby="basic-addon1"/>
</div>

{/* <div class="input-group mb-3">
  <span class="input-group-text">Salary Rs.</span>
  <input  onChange={(e)=> setSalary(e.target.value)} type="number" class="form-control" aria-label="Amount (to the nearest dollar)"/>
  <span class="input-group-text">.00</span>
</div> */}



        <button onClick={createCustomer} className="btn btn-outline-success">Submit</button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default CreateCustomer