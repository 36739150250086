import React, { useState, useEffect,useRef  } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import POExpense from "./POExpense";
import DashboardHeader from "../Admin/Dashboard/DashboardHeader";
import "./PODetails.css";
import PieChart from "./PieChart";

const PODetails = () => {
  const navigate = useNavigate();
  const [poDetails, setPoDetails] = useState(null);
  const [message, setMessage] = useState("");
  const [materialsFile, setMaterialsFile] = useState(null);
  const [collectedFile, setCollectedFile] = useState(null);
  const [billReceiptFile, setBillReceiptFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const expenseRef = useRef(null); 
  const [showExpenseForm, setShowExpenseForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // New loading state
  const { id } = useParams();

  useEffect(() => {
    const fetchPODetails = async () => {
      try {
        const response = await axios.get(
          `https://apenterprise-server.onrender.com/po-details/search-po/${id}`
        );
        setPoDetails(response.data);
      } catch (error) {
        console.error("Error fetching PO details:", error);
      } finally {
        setIsLoading(false); // Stop loading animation
      }
    };

    fetchPODetails();
  }, [id, poDetails]);

  const handleFileUpload = async (type) => {
    if (!window.confirm("Are you sure you want to upload the file?")) {
        return;
      }
    const formData = new FormData();
    let endpoint = "";
    let file = null;

    if (type === "srn") {
      formData.append("srnPdf", materialsFile);
      endpoint = "upload-srn";
      file = materialsFile;
    } else if (type === "sirn") {
      formData.append("sirnPdf", collectedFile);
      endpoint = "upload-sirn";
      file = collectedFile;
    } else if (type === "bill") {
      formData.append("billReceiptPdf", billReceiptFile);
      endpoint = "upload-bill-receipt";
      file = billReceiptFile;
    }

    if (!file) return;

    formData.append("poNumber", id);

    try {
      setIsUploading(true);
      const response = await axios.post(
        `https://apenterprise-server.onrender.com/po-details/${endpoint}`,
        formData
      );
      setMessage(response.data.message);
      setMaterialsFile(null);
      setCollectedFile(null);
      setBillReceiptFile(null);

      // Refresh PO details after upload
      const updatedDetails = await axios.get(
        `https://apenterprise-server.onrender.com/po-details/search-po/${id}`
      );
      setPoDetails(updatedDetails.data);
    } catch (error) {
      console.error("Error uploading file:", error);
      setMessage(
        error.response?.data?.message || "An error occurred while uploading."
      );
    } finally {
      setIsUploading(false);
    }
  };

//   const toggleExpenseForm = () => {
//     setShowExpenseForm(!showExpenseForm);
//   };

  const toggleExpenseForm = () => {
    setShowExpenseForm(!showExpenseForm);
    setTimeout(() => {
      expenseRef.current?.scrollIntoView({ behavior: "smooth" }); // Smooth scroll to Expense Management section
    }, 100); // Add slight delay for smooth rendering before scrolling
  };

  const handleBack = () => {
    navigate(-1);
  };

  const redirectLabourExpenseList = (id) => {
    navigate(`/labour-expense-list/${id}`);
  };

  const redirectOtherExpenseList = (id) => {
    navigate(`/other-expense-list/${id}`);
  };

  if (isLoading) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <div
          className="loader"
          style={{
            border: "4px solid #f3f3f3",
            borderTop: "4px solid #3498db",
            borderRadius: "50%",
            width: "50px",
            height: "50px",
            animation: "spin 1s linear infinite",
            margin: "0 auto",
          }}
        ></div>
        <p style={{ color: "#555", marginTop: "10px" }}>Loading...</p>
      </div>
    );
  }

  if (!poDetails) return <p>{message || "Loading..."}</p>;

  const canUploadFiles =
    !poDetails.materialsPdfUrl ||
    (!poDetails.billRecieptPdfUrl && !poDetails.isBillSubmitted);

  return (
    <div>
      <DashboardHeader />

      <div
        className="container-poDetails"
        style={{
          display: "flex",
          flexDirection: "row", // Default for desktop
          flexWrap: "wrap",
          maxWidth: "1200px",
          margin: "50px auto",
          gap: "20px",
          fontFamily: "Arial, sans-serif",
        }}
      >
        <div
          style={{
            flex: 2,
            backgroundColor: "#fff",
            borderRadius: "10px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            padding: "20px",
          }}
        >
          <FontAwesomeIcon
            onClick={handleBack}
            icon={faArrowLeftLong}
            size="2x"
            style={{ cursor: "pointer", marginBottom: "20px" }}
          />
          <h2
            style={{
              textAlign: "center",
              marginBottom: "20px",
              color: "#333",
              fontWeight: "bold",
            }}
          >
            PO Details
          </h2>
          <div
            className="po-details"
            style={{
              flex: 2,
              backgroundColor: "#fff",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
              padding: "20px",
            }}
          >
            <div style={{ width: "50%", marginRight: "1.5em" }}>
              <p>
                <strong>PO Date:</strong>{" "}
                {/* {new Date(poDetails.dateOfOrder).toISOString().split("T")[0]} */}
                {new Date(poDetails.dateOfOrder).toLocaleDateString("en-GB")}

              </p>
              <p>
                <strong>PO Number:</strong> {poDetails?.poNumber}
              </p>
              <p>
                <strong>Location:</strong> {poDetails?.location}
              </p>
              <p>
                <strong>Division:</strong> {poDetails?.division}
              </p>
              <p>
                <strong>Client Name:</strong> {poDetails?.clientName}
              </p>
              <p>
                <strong>Amount:</strong> ₹{poDetails?.amount}
              </p>
              <p>
                <strong>Order Number:</strong> {poDetails?.orderNumber}
              </p>
              <p>
                <strong>Uploaded By:</strong> {poDetails.createdBy}
              </p>
              {poDetails?.paymentRecieved && <p>
                <strong>Security Claimed:</strong>{" "}
                <span
                  style={{
                    color: poDetails.isBillSubmitted ? "#28a745" : "#dc3545",
                    fontWeight: "bold",
                    fontSize: "1.1em",
                  }}
                >
                  {poDetails?.isSecurityClaimed ? "✔ Yes" : "✘ No"}
                </span>
              </p>}

              {/* Display POExpense component when toggled */}
            </div>

            <div style={{ width: "50%" }}>
              <p>
                <strong>Bill Submitted:</strong>{" "}
                <span
                  style={{
                    color: poDetails.isBillSubmitted ? "#28a745" : "#dc3545",
                    fontWeight: "bold",
                    fontSize: "1.1em",
                  }}
                >
                  {poDetails.isBillSubmitted ? "✔ Yes" : "✘ No"}
                </span>
              </p>
              <p>
                <strong>Payment Recieved:</strong>{" "}
                <span
                  style={{
                    color: poDetails.isBillSubmitted ? "#28a745" : "#dc3545",
                    fontWeight: "bold",
                    fontSize: "1.1em",
                  }}
                >
                  {poDetails?.paymentRecieved ? "✔ Yes" : "✘ No"}
                </span>
              </p>

              <p>
                <strong>PO File PDF:</strong>{" "}
                {poDetails.fileViewLink ? (
                  <a
                    href={poDetails.fileViewLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#007BFF", textDecoration: "underline" }}
                  >
                    View
                  </a>
                ) : (
                  "Not Uploaded"
                )}
              </p>
              <p>
                <strong>Bill Receipt:</strong>{" "}
                {poDetails.billRecieptPdfUrl ? (
                  <a
                    href={poDetails.billRecieptPdfUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#007BFF", textDecoration: "underline" }}
                  >
                    View
                  </a>
                ) : (
                  "Not Uploaded"
                )}
              </p>
              <p>
                <strong>SRN File:</strong>
              </p>
              <ul>
                {poDetails.materialsPdfUrl.map((item, index) => (
                  <li key={index}>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#007BFF", textDecoration: "underline" }}
                    >
                      {new Date(item.date).toLocaleDateString("en-GB")}
                    </a>
                  </li>
                ))}
              </ul>
              <p>
                <strong>SIRN File:</strong>
              </p>
              <ul>
                {poDetails.materialsCollectionUrls.map((item, index) => (
                  <li key={index}>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#007BFF", textDecoration: "underline" }}
                    >
                      {new Date(item.date).toLocaleDateString("en-GB")}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {canUploadFiles && (
          <div
            style={{
              flex: 1,
              backgroundColor: "#f9f9f9",
              borderRadius: "10px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
              padding: "20px",
              width: "100%",
              marginBottom: "1.5em", // Full width on small screens
              // maxWidth: "calc(100% - 40px)", // Adjust for padding or gap
            }}
          >
            <h3
              style={{
                color: "#333",
                marginBottom: "15px",
                fontWeight: "bold",
              }}
            >
              Upload Files
            </h3>

            {!poDetails.billRecieptPdfUrl && !poDetails.isBillSubmitted && (
              <>
                <div
                  className="upload-files"
                  style={{
                    flex: 1,
                    backgroundColor: "#f9f9f9",
                    borderRadius: "10px",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                    padding: "20px",
                    marginBottom: "1.5em",
                    width: "100%", // Full width on small screens
                  }}
                >
                  <label
                    style={{
                      fontWeight: "bold",
                      color: "#333",
                      display: "block",
                      marginBottom: "10px",
                    }}
                  >
                    Upload SIRN PDF:
                  </label>
                  <input
                    type="file"
                    onChange={(e) => setCollectedFile(e.target.files[0])}
                    style={{
                      display: "block",
                      marginBottom: "10px",
                      padding: "8px",
                      border: "1px solid #ddd",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                  />
                  <button
                    onClick={() => handleFileUpload("sirn")}
                    disabled={!collectedFile}
                    style={{
                      display: "block",
                      width: "100%",
                      padding: "10px",
                      backgroundColor: collectedFile ? "#007BFF" : "#ccc",
                      color: "#fff",
                      border: "none",
                      borderRadius: "5px",
                      fontWeight: "bold",
                      cursor: collectedFile ? "pointer" : "not-allowed",
                    }}
                  >
                    Upload
                  </button>
                </div>

                <div
                  style={{
                    marginBottom: "20px",
                    padding: "15px",
                    border: "1px solid #ddd",
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <label
                    style={{
                      fontWeight: "bold",
                      color: "#333",
                      display: "block",
                      marginBottom: "10px",
                    }}
                  >
                    Upload SRN PDF:
                  </label>
                  <input
                    type="file"
                    onChange={(e) => setMaterialsFile(e.target.files[0])}
                    style={{
                      display: "block",
                      marginBottom: "10px",
                      padding: "8px",
                      border: "1px solid #ddd",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                  />
                  <button
                    onClick={() => handleFileUpload("srn")}
                    disabled={!materialsFile}
                    style={{
                      display: "block",
                      width: "100%",
                      padding: "10px",
                      backgroundColor: materialsFile ? "#007BFF" : "#ccc",
                      color: "#fff",
                      border: "none",
                      borderRadius: "5px",
                      fontWeight: "bold",
                      cursor: materialsFile ? "pointer" : "not-allowed",
                    }}
                  >
                    Upload
                  </button>
                </div>

                <div
                  style={{
                    marginBottom: "20px",
                    padding: "15px",
                    border: "1px solid #ddd",
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <label
                    style={{
                      fontWeight: "bold",
                      color: "#333",
                      display: "block",
                      marginBottom: "10px",
                    }}
                  >
                    Upload Bill Receipt PDF:
                  </label>
                  <input
                    type="file"
                    onChange={(e) => setBillReceiptFile(e.target.files[0])}
                    style={{
                      display: "block",
                      marginBottom: "10px",
                      padding: "8px",
                      border: "1px solid #ddd",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                  />
                  <button
                    onClick={() => handleFileUpload("bill")}
                    disabled={!billReceiptFile}
                    style={{
                      display: "block",
                      width: "100%",
                      padding: "10px",
                      backgroundColor: billReceiptFile ? "#007BFF" : "#ccc",
                      color: "#fff",
                      border: "none",
                      borderRadius: "5px",
                      fontWeight: "bold",
                      cursor: billReceiptFile ? "pointer" : "not-allowed",
                    }}
                  >
                    Upload
                  </button>
                </div>
              </>
            )}
          </div>
        )}
        {isUploading && (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <div
              className="loader"
              style={{
                border: "4px solid #f3f3f3",
                borderTop: "4px solid #3498db",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                animation: "spin 1s linear infinite",
                margin: "0 auto",
              }}
            ></div>
            <p style={{ color: "#555", marginTop: "10px" }}>Uploading...</p>

            {message && (
              <p
                style={{
                  color: message.includes("successfully") ? "green" : "red",
                  marginTop: "20px",
                }}
              >
                {message}
              </p>
            )}
          </div>
        )}
        {/* <button
            className="show-expense-button"
          onClick={toggleExpenseForm}
          style={{
            margin: "20px auto",
            display: "block",
            padding: "10px 20px",
            backgroundColor: showExpenseForm ? "#ed2b75" : "#007BFF",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          {showExpenseForm ? "Close Expenses" : "Show Expenses"}
        </button> */}
        {/* {showExpenseForm && (
          <div ref={expenseRef}  style={{ display: "flex",flexDirection:"column", width:"100%" }}>
              <h1 className="po-expenses-title">Expense Management</h1>
                        <div>
                            <div style={{display:"flex", justifyContent:"center"}}>
                            
              <button style={{backgroundColor:"#fcba03"}} className="button-expense-list" onClick={() => redirectLabourExpenseList(id)}>
                Show Labour Expenses
              </button>
              <button style={{backgroundColor:"#fc7f03"}} className="button-expense-list" onClick={() => redirectOtherExpenseList(id)}>
                Show Other Expenses
              </button>
              </div>
            </div>
            <div className="pie-container-mobile" style={{display:"flex",justifyContent:"space-around"}}>
            <div className="expense-container-mobile">
            
              <POExpense poDetails={poDetails} />
            </div>
            <div className="piechart-mobile">
                <PieChart poDetails={poDetails} />
            </div>
            </div>

          </div>
        )} */}
      </div>
    </div>
  );
};

export default PODetails;
