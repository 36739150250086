import React, { useState, useEffect } from 'react';
import './AssignWork.css';
import DashboardHeader from '../Admin/Dashboard/DashboardHeader';

const AssignWork = () => {
  const [employees, setEmployees] = useState([]);
  const [benchEmployees, setBenchEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [siteLocation, setSiteLocation] = useState('');
  const [jobNature, setJobNature] = useState('');
  const [assignedWork, setAssignedWork] = useState([]);
  const [savedSiteLocations, setSavedSiteLocations] = useState([]);
  const [savedJobNatures, setSavedJobNatures] = useState([]);
  const [filteredSiteLocations, setFilteredSiteLocations] = useState([]);
  const [filteredJobNatures, setFilteredJobNatures] = useState([]);
  const [showSiteSuggestions, setShowSiteSuggestions] = useState(false);
  const [showJobSuggestions, setShowJobSuggestions] = useState(false);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await fetch('https://apenterprise-server.onrender.com/payee');
        if (!response.ok) {
          throw new Error('Failed to fetch employees');
        }
        const data = await response.json();
        setEmployees(data);
        setBenchEmployees(data);
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };

    fetchEmployees();
  }, []);

  const handleAssign = () => {
    if (selectedEmployee && siteLocation && jobNature) {
      const assigned = {
        employee: selectedEmployee,
        location: siteLocation,
        jobNature: jobNature,
        postedTime: new Date().toLocaleString(),
        isCompleted: false,
      };
      setAssignedWork([...assignedWork, assigned]);
      
      if (!savedSiteLocations.includes(siteLocation)) {
        setSavedSiteLocations([...savedSiteLocations, siteLocation]);
      }
      if (!savedJobNatures.includes(jobNature)) {
        setSavedJobNatures([...savedJobNatures, jobNature]);
      }

      setBenchEmployees(benchEmployees.filter(emp => emp.name !== selectedEmployee));
      setSelectedEmployee('');
      setSiteLocation('');
      setJobNature('');
    } else {
      alert('Please fill in all fields');
    }
  };

  const handleRemove = (employeeName) => {
    const confirmation = window.confirm(`Are you sure you want to remove ${employeeName}?`);
    if (confirmation) {
      const workAssignment = assignedWork.find(work => work.employee === employeeName);

      if (workAssignment) {
        setAssignedWork(assignedWork.filter(work => work.employee !== employeeName));
        setBenchEmployees([...benchEmployees, { id: Date.now(), name: employeeName }]);
      }
    }
  };

  const handleWorkFinished = (employeeName) => {
    const confirmation = window.confirm(`Do you want to mark the work for ${employeeName} as complete?`);
    if (confirmation) {
      const completedTime = new Date().toLocaleString();

      setAssignedWork(prevWork =>
        prevWork.map(work =>
          work.employee === employeeName
            ? { ...work, isCompleted: true, completedTime }
            : work
        )
      );

      setBenchEmployees([...benchEmployees, { id: Date.now(), name: employeeName }]);
    }
  };

  const handleSiteLocationChange = (e) => {
    const inputValue = e.target.value;
    setSiteLocation(inputValue);
    if (inputValue) {
      setFilteredSiteLocations(
        savedSiteLocations
          .filter(location => location.toLowerCase().includes(inputValue.toLowerCase()))
          .slice(0, 6)
      );
      setShowSiteSuggestions(true);
    } else {
      setShowSiteSuggestions(false);
    }
  };

  const handleJobNatureChange = (e) => {
    const inputValue = e.target.value;
    setJobNature(inputValue);
    if (inputValue) {
      setFilteredJobNatures(
        savedJobNatures
          .filter(nature => nature.toLowerCase().includes(inputValue.toLowerCase()))
          .slice(0, 6)
      );
      setShowJobSuggestions(true);
    } else {
      setShowJobSuggestions(false);
    }
  };

  const handleSiteSuggestionClick = (suggestion) => {
    setSiteLocation(suggestion);
    setShowSiteSuggestions(false);
  };

  const handleJobSuggestionClick = (suggestion) => {
    setJobNature(suggestion);
    setShowJobSuggestions(false);
  };

  return (
    <>
    <DashboardHeader/>
    <div className="assign-work-container">
      <h2>Assign Work and Employee Locations</h2>

      <div className="assign-form">
        <div className="form-group">
          <label>Select Employee:</label>
          <select
            value={selectedEmployee}
            onChange={(e) => setSelectedEmployee(e.target.value)}
          >
            <option value="">Select an employee</option>
            {benchEmployees.map(employee => (
              <option key={employee.id} value={employee.name}>
                {employee.name}
              </option>
            ))}
          </select>
          <div>Available Members: {benchEmployees.length}</div>
        </div>

        <div className="form-group">
          <label>Site Location:</label>
          <input
            type="text"
            placeholder="Enter or select a site location"
            value={siteLocation}
            onChange={handleSiteLocationChange}
            onFocus={() => setShowSiteSuggestions(true)}
            onBlur={() => setShowSiteSuggestions(false)}
          />
          {showSiteSuggestions && filteredSiteLocations.length > 0 && (
            <ul className="suggestions-list">
              {filteredSiteLocations.map((location, index) => (
                <li 
                  key={index} 
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => handleSiteSuggestionClick(location)}
                >
                  {location}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="form-group">
          <label>Nature of Job:</label>
          <input
            type="text"
            placeholder="Enter or select a job nature"
            value={jobNature}
            onChange={handleJobNatureChange}
            onFocus={() => setShowJobSuggestions(true)}
            onBlur={() => setShowJobSuggestions(false)}
          />
          {showJobSuggestions && filteredJobNatures.length > 0 && (
            <ul className="suggestions-list">
              {filteredJobNatures.map((nature, index) => (
                <li 
                  key={index} 
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => handleJobSuggestionClick(nature)}
                >
                  {nature}
                </li>
              ))}
            </ul>
          )}
        </div>

        <button className="assign-button" onClick={handleAssign}>
          Assign Work
        </button>
      </div>

      <div className="assigned-work-list">
        <h3>Assigned Work</h3>
        <ul>
          {assignedWork.map((work, index) => (
            <li key={index} className={work.isCompleted ? 'completed' : ''}>
              <h4 className='employee-header-name'><strong>{work.employee}</strong></h4><br/> 
              <strong>Site Name / Plot No:</strong> {work.location} <br />
              <strong>Nature of Job:</strong> {work.jobNature} <br />
              <strong>Posted Time:</strong> {work.postedTime} <br />
              {work.isCompleted ? (<><strong>Completed at: </strong><br/>{work.completedTime}</>) : (<><strong>In Progress</strong></>)}
              {work.isCompleted && <span className="badge">Completed</span>}
              {!work.isCompleted && (
                <>
                  <button 
                    className="remove-button" 
                    onClick={() => handleRemove(work.employee)}
                  >
                    Remove
                  </button>
                  <button 
                    className="finished-button"
                    onClick={() => handleWorkFinished(work.employee)}
                  >
                    Mark as Finished
                  </button>
                </>
              )}
            </li>
          ))}
        </ul>
      </div>

    </div>
    </>
  );
};

export default AssignWork;
