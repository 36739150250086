import React, { useState } from "react";
import axios from "axios";
import "../POchecker/POExpense.css";
import "chart.js/auto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const ProfitExpense = () => {
  const [labourExpenses, setLabourExpenses] = useState([]);
  const [otherExpenses, setOtherExpenses] = useState([]);
  const [message, setMessage] = useState(""); 

  const navigate = useNavigate();
  
    // Navigate Back
    const handleBack = () => {
      navigate(-1);
    };

  const [labourEntry, setLabourEntry] = useState({
    noOfLabours: "",
    rate: "",
    vanCost: "",
    totalAmount: "",
    description: "",
  });

  const [otherEntry, setOtherEntry] = useState({
    amount: "",
    description: "",
  });

  const handleLabourChange = (e) => {
    const { name, value } = e.target;
    const updatedLabourEntry = { ...labourEntry, [name]: value };
    if (name === "noOfLabours" || name === "rate" || name === "vanCost") {
      const totalAmount =
        (Number(updatedLabourEntry.noOfLabours) || 0) *
          (Number(updatedLabourEntry.rate) || 0) +
        (Number(updatedLabourEntry.vanCost) || 0);
      updatedLabourEntry.totalAmount = totalAmount.toFixed(2);
    }
    setLabourEntry(updatedLabourEntry);
  };

  const handleOtherChange = (e) => {
    const { name, value } = e.target;
    setOtherEntry({ ...otherEntry, [name]: value });
  };


const submitLabourExpense = async () => {
    if (
      !labourEntry.noOfLabours ||
      !labourEntry.rate ||
      !labourEntry.vanCost ||
      !labourEntry.totalAmount ||
      !labourEntry.description ||
      !labourEntry.date
    ) {
      setMessage("Please fill out all fields for Labour Expenses.");
      return;
    }
  
    if (!window.confirm("Are you sure you want to add this labour expense?")) {
      return;
    }
    try {
      const response = await axios.post(
        "https://apenterprise-server.onrender.com/po-details/add-total-expense",
        {
          date: labourEntry.date,
          expenseType: "labour",
          expense: labourEntry,
        }
      );
      setMessage("Labour expense added successfully!");
  
      // Reset form fields and clear the message after a short delay
      setTimeout(() => setMessage(""), 3000);
      setLabourExpenses([...labourExpenses, labourEntry]);
      setLabourEntry({
        noOfLabours: "",
        rate: "",
        vanCost: "",
        totalAmount: "",
        description: "",
        date: "",
      });
    } catch (error) {
      console.error(error);
      setMessage("Failed to add labour expense. Please try again.");
    }
  };
  
  const submitOtherExpense = async () => {
    if (!otherEntry.amount || !otherEntry.description || !otherEntry.date) {
      setMessage("Please fill out all fields for Other Expenses.");
      return;
    }
  
    if (!window.confirm("Are you sure you want to add this other expense?")) {
      return;
    }
    try {
      const response = await axios.post(
        "https://apenterprise-server.onrender.com/po-details/add-total-expense",
        {
          date: otherEntry.date,
          expenseType: "other",
          expense: otherEntry,
        }
      );
      setMessage("Other expense added successfully!");
  
      // Reset form fields and clear the message after a short delay
      setTimeout(() => setMessage(""), 3000);
      setOtherExpenses([...otherExpenses, otherEntry]);
      setOtherEntry({
        amount: "",
        description: "",
        date: "",
      });
    } catch (error) {
      console.error(error);
      setMessage("Failed to add other expense. Please try again.");
    }
  };

  const redirectToExpenses = () => {
    navigate('/get-total-expenses');
  }

  
  return (
    <div className="po-expense-container">
      {message && <p style={{ color: "green" }} className="message">{message}</p>}
      <div>
        <div className="expense-section">
              <FontAwesomeIcon
                      onClick={handleBack}
                      icon={faArrowLeftLong}
                      size="2x"
                      style={{ cursor: "pointer", marginBottom: "20px" }}
                    />
            <div style={{display:"flex",justifyContent:"center"}}>
            <button style={{marginBottom:"1em"}} className="button-all-expenses" onClick={redirectToExpenses}>Show All Expenses</button>
            </div>
          <h3 className="section-title">Labour Expenses</h3>
          <div className="form-group">
            <input
              type="date"
              name="date"
              value={labourEntry.date}
              onChange={handleLabourChange}
              className="form-input"
            />
            <input
              type="number"
              name="noOfLabours"
              value={labourEntry.noOfLabours}
              onChange={handleLabourChange}
              placeholder="No of Labours"
              className="form-input"
            />
            <input
              type="number"
              name="rate"
              value={labourEntry.rate}
              onChange={handleLabourChange}
              placeholder="Rate"
              className="form-input"
            />
          </div>
          <div className="form-group">
            <input
              type="number"
              name="vanCost"
              value={labourEntry.vanCost}
              onChange={handleLabourChange}
              placeholder="Van Cost"
              className="form-input"
            />
            <input
              type="number"
              name="totalAmount"
              value={labourEntry.totalAmount}
              readOnly
              className="form-input read-only"
            />
          </div>
          <textarea
            name="description"
            value={labourEntry.description}
            onChange={handleLabourChange}
            placeholder="Description"
            className="form-input"
            style={{ width: "100%", height: "100px" }}
          />
          <button onClick={submitLabourExpense} className="add-btn">
            Add Labour Expense
          </button>
        </div>
      </div>

      <div className="expense-section">
        <h3 className="section-title">Other Expenses</h3>
        <div className="form-group">
          <input
            type="date"
            name="date"
            value={otherEntry.date}
            onChange={handleOtherChange}
            className="form-input"
          />
          <input
            type="number"
            name="amount"
            value={otherEntry.amount}
            onChange={handleOtherChange}
            placeholder="Amount"
            className="form-input"
          />
        </div>
        <textarea
          name="description"
          value={otherEntry.description}
          onChange={handleOtherChange}
          placeholder="Description"
          className="form-input"
          style={{ width: "100%", height: "100px" }}
        />
        <button onClick={submitOtherExpense} className="add-btn">
          Add Other Expense
        </button>
      </div>
    </div>
  );
};

export default ProfitExpense;
