import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import "./POExpenseLabourList.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DashboardHeader from "../Admin/Dashboard/DashboardHeader";
import logo from "../Header/letterpad.png";


const POExpenseOtherList = () => {
  const [otherExpenses, setOtherExpenses] = useState([]);
   const [poDetails, setPoDetails] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
       const { id } = useParams();

  const itemsPerPage = 10;

  useEffect(() => {
    const fetchOtherExpenses = async () => {
      try {
        const poResponse = await axios.get(
          `https://apenterprise-server.onrender.com/po-details/search-po/${id}`
        );
        setOtherExpenses(poResponse.data.otherExpenses);
        setPoDetails(poResponse.data);
      } catch (err) {
        setError("Failed to fetch labour expenses.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchOtherExpenses();
    }
  }, [id]);

  if (loading) {
    return <p>Loading labour expenses...</p>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  const totalPages = Math.ceil(otherExpenses.length / itemsPerPage);

  const handleBack = () => {
    navigate(-1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear();
  
    // Determine the ordinal suffix (st, nd, rd, th)
    const ordinal = (n) => {
      if (n > 3 && n < 21) return "th"; // Covers 11th - 19th
      switch (n % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };
  
    return `${day}${ordinal(day)} ${month}, ${year}`;
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = otherExpenses.slice(startIndex, endIndex);

  const totalAmount = otherExpenses.reduce((sum, expense) => sum + (expense.amount || 0), 0);


  const generatePDF = () => {
    if (!poDetails) return;
  
    const doc = new jsPDF();
  
    // Set up the logo like a letterhead
    const imgWidth = 180; // Full width of the page (A4 size is 210mm wide, leaving margins)
    const imgHeight = 30; // Adjust the height proportionally
    doc.addImage(logo, "PNG", 15, 10, imgWidth, imgHeight); // Center the logo at the top
  
    // Add the title beneath the logo
    const titleYPosition = imgHeight + 20; // Adjust vertical spacing after the logo
    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.text("Other Expenses Report", 105, titleYPosition, { align: "center" }); // Center-align the text
  
    // Add proper spacing for the PO details section
    const poDetailsStartY = titleYPosition + 15; // Add some spacing after the title
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`PO Number: ${poDetails.poNumber}`, 14, poDetailsStartY);
    doc.text(`Location: ${poDetails.location}`, 14, poDetailsStartY + 5);
    doc.text(`Division: ${poDetails.division}`, 14, poDetailsStartY + 10);
    doc.text(
      `PO Date: ${new Date(poDetails.dateOfOrder).toLocaleDateString("en-GB")}`,
      14,
      poDetailsStartY + 15
    );
    doc.text(`Client Name: ${poDetails.clientName}`, 14, poDetailsStartY + 20);
  
    // Start the table after the PO details
    const tableStartY = poDetailsStartY + 30; // Ensure proper spacing between PO details and the table
    const tableColumn = ["S.No", "Date", "Amount", "Description"];
    const tableRows = [];
  
    // Populate table rows
    otherExpenses.forEach((expense, index) => {
      const rowData = [
        index + 1,
        new Date(expense.date).toLocaleDateString("en-GB"),
        new Intl.NumberFormat("en-IN", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(expense.amount || 0), // Format the amount
        expense.description || "N/A",
      ];
      tableRows.push(rowData);
    });
  
    // Add the table
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: tableStartY, // Start the table after the PO details
    });
  
    // Add total amount at the bottom with proper spacing and styling
    const totalAmountBoxY = doc.lastAutoTable.finalY + 15; // Ensure proper spacing after the table
    const boxX = 14;
    const boxWidth = 180;
    const boxHeight = 10;
  
    doc.setFillColor(230, 230, 230); // Light gray background for the total amount box
    doc.rect(boxX, totalAmountBoxY, boxWidth, boxHeight, "F"); // Draw the filled rectangle
  
    const totalOtherAmount = otherExpenses.reduce(
      (sum, expense) => sum + (expense.amount || 0),
      0
    );
    const formattedOtherAmount = new Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(totalOtherAmount);
  
    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.setTextColor(50, 50, 50); // Dark gray text
    doc.text(
      `Total Other Expenses: Rs ${formattedOtherAmount}`,
      boxX + 5, // Add padding inside the box
      totalAmountBoxY + 7 // Center vertically inside the box
    );
  
    // Add "Report generated on {Date}" at the end
    const reportGeneratedY = totalAmountBoxY + 20; // Add some spacing after the total amount box
    doc.setFontSize(10);
    doc.setFont("helvetica", "italic");
    const currentDate = formatDate(new Date());
    const currentTime = new Date().toLocaleTimeString();
    doc.text(
      `Report generated on: ${currentDate} at ${currentTime}`,
      14,
      reportGeneratedY
    );
  
    // Save the PDF
    doc.save(`Other_Expenses_Report_${poDetails.poNumber}.pdf`);
  };
  


  return (
    <div>
      <DashboardHeader/>
    <div className="labour-expenses-container">
           <FontAwesomeIcon
                onClick={handleBack}
                icon={faArrowLeftLong}
                size="2x"
                style={{ cursor: "pointer", marginBottom: "20px" }}
              />
      <h2 className="labour-expenses-title">Other Expenses</h2>
      <button onClick={generatePDF} className="generate-pdf-btn">
          Download PDF
        </button>
      <table className="labour-expenses-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Date</th>
            <th>Amount</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.length > 0 ? (
            currentItems.map((expense, index) => (
              <tr key={index}>
                <td>{startIndex + index + 1}</td>
                <td>{new Date(expense.date).toLocaleDateString("en-GB")}</td>
                <td>{expense.amount}</td>
                <td>{expense.description}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="no-data">No labour expenses recorded.</td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={index + 1 === currentPage ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}
      </div>

      {currentPage === totalPages && (
        <div className="total-amount-container">
          <h3>Total Amount: {totalAmount.toFixed(2)}</h3>
        </div>
      )}
    </div>
    </div>
  );
};

export default POExpenseOtherList;

