// import React, { useContext, useEffect, useState } from 'react';
// import axios from 'axios';
// import UserContext from "../../UserContext";
// import DashboardHeader from '../Admin/Dashboard/DashboardHeader';
// import { useNavigate } from 'react-router-dom';

// const POchecker = () => {
//     const [formData, setFormData] = useState({
//         location: '',
//         dateOfOrder: '',
//         poNumber: '',
//         clientName: '',
//         amount: '',
//         orderNumber: '',
//         username: ''
//     });
//     const [file, setFile] = useState(null);
//     const [message, setMessage] = useState('');
//     const { isAdmin, userDetails } = useContext(UserContext);
//     const navigate = useNavigate();

//     useEffect(() => {
//         if (userDetails?.user.email) {
//             setFormData((prevFormData) => ({
//                 ...prevFormData,
//                 username: userDetails.user.email.split("@")[0] === 'admin' ? 'Irfan' : userDetails.user.email.split("@")[0],
//             }));
//         }
//     }, [userDetails]);

//     const handleChange = (e) => {
//         setFormData({
//             ...formData,
//             [e.target.name]: e.target.value,
//         });
//     };

//     const handleFileChange = (e) => {
//         setFile(e.target.files[0]);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         if (!file) {
//             setMessage('Please upload a file.');
//             return;
//         }

//         const formPayload = new FormData();
//         Object.keys(formData).forEach((key) => {
//             formPayload.append(key, formData[key]);
//         });
//         formPayload.append('pdfFile', file);

//         try {
//             const response = await axios.post('https://apenterprise-server.onrender.com/po-details/', formPayload, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data',
//                 },
//             });

//             setMessage(`File uploaded successfully! View Link: ${response.data.fileViewLink}`);
//         } catch (error) {
//             setMessage(error?.response?.data?.message || error.message);
//             console.error(error);
//         }
//     };

//     return (
//         <div>
//             <DashboardHeader />
//             <div
//                 style={{
//                     padding: '20px',
//                     maxWidth: '600px',
//                     margin: '50px auto',
//                     boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
//                     borderRadius: '10px',
//                     backgroundColor: '#f9f9f9',
//                 }}
//             >
//                 <h1 style={{ textAlign: 'center', marginBottom: '20px', color: '#333', fontWeight: 'bold' }}>
//                     Upload PO File
//                 </h1>
//                 <button
//                     onClick={() => navigate('/view-po')} 
//                     style={{
//                         width: '20%',
//                         marginTop: '20px',
//                         padding: '12px',
//                         borderRadius: '5px',
//                         border: 'none',
//                         backgroundColor: '#007BFF',
//                         color: '#fff',
//                         fontWeight: 'bold',
//                         cursor: 'pointer',
//                         transition: 'background-color 0.3s',
//                     }}
//                     onMouseOver={(e) => (e.target.style.backgroundColor = '#0056b3')}
//                     onMouseOut={(e) => (e.target.style.backgroundColor = '#007BFF')}
//                 >
//                     View PO
//                 </button>
//                 <form onSubmit={handleSubmit}>
//                     <div style={{ marginBottom: '15px', marginTop:'1em' }}>
//                         <label style={{ fontWeight: 'bold', color: '#555' }}>Location:</label>
//                         <input
//                             type="text"
//                             name="location"
//                             value={formData.location}
//                             onChange={handleChange}
//                             required
//                             style={{
//                                 width: '100%',
//                                 padding: '10px',
//                                 borderRadius: '5px',
//                                 border: '1px solid #ccc',
//                                 marginTop: '5px',
//                                 color:"black",
//                                 backgroundColor: '#fff',
//                             }}
//                         />
//                     </div>
//                     <div style={{ marginBottom: '15px' }}>
//                         <label style={{ fontWeight: 'bold', color: '#555' }}>Date of Order:</label>
//                         <input
//                             type="date"
//                             name="dateOfOrder"
//                             value={formData.dateOfOrder}
//                             onChange={handleChange}
//                             required
//                             style={{
//                                 width: '100%',
//                                 padding: '10px',
//                                 borderRadius: '5px',
//                                 border: '1px solid #ccc',
//                                 color:"black",
//                                 marginTop: '5px',
//                                 backgroundColor: '#fff',
//                             }}
//                         />
//                     </div>
//                     <div style={{ marginBottom: '15px' }}>
//                         <label style={{ fontWeight: 'bold', color: '#555' }}>PO Number:</label>
//                         <input
//                             type="text"
//                             name="poNumber"
//                             value={formData.poNumber}
//                             onChange={handleChange}
//                             required
//                             style={{
//                                 width: '100%',
//                                 padding: '10px',
//                                 color:"black",
//                                 borderRadius: '5px',
//                                 border: '1px solid #ccc',
//                                 marginTop: '5px',
//                                 backgroundColor: '#fff',
//                             }}
//                         />
//                     </div>
//                     <div style={{ marginBottom: '15px' }}>
//                         <label style={{ fontWeight: 'bold', color: '#555' }}>Client Name:</label>
//                         <input
//                             type="text"
//                             name="clientName"
//                             value={formData.clientName}
//                             onChange={handleChange}
//                             required
//                             style={{
//                                 width: '100%',
//                                 padding: '10px',
//                                 borderRadius: '5px',
//                                 color:"black",
//                                 border: '1px solid #ccc',
//                                 marginTop: '5px',
//                                 backgroundColor: '#fff',
//                             }}
//                         />
//                     </div>
//                     <div style={{ marginBottom: '15px' }}>
//                         <label style={{ fontWeight: 'bold', color: '#555' }}>Amount:</label>
//                         <input
//                             type="number"
//                             name="amount"
//                             value={formData.amount}
//                             onChange={handleChange}
//                             required
//                             style={{
//                                 width: '100%',
//                                 padding: '10px',
//                                 borderRadius: '5px',
//                                 color:"black",
//                                 border: '1px solid #ccc',
//                                 marginTop: '5px',
//                                 backgroundColor: '#fff',
//                             }}
//                         />
//                     </div>
//                     <div style={{ marginBottom: '15px' }}>
//                         <label style={{ fontWeight: 'bold', color: '#555' }}>Order Number:</label>
//                         <input
//                             type="text"
//                             name="orderNumber"
//                             value={formData.orderNumber}
//                             onChange={handleChange}
//                             required
//                             style={{
//                                 width: '100%',
//                                 padding: '10px',
//                                 borderRadius: '5px',
//                                 border: '1px solid #ccc',
//                                 marginTop: '5px',
//                                 color:"black",
//                                 backgroundColor: '#fff',
//                             }}
//                         />
//                     </div>
//                     <div style={{ marginBottom: '15px' }}>
//                         <label style={{ fontWeight: 'bold', color: '#555' }}>File:</label>
//                         <input
//                             type="file"
//                             onChange={handleFileChange}
//                             accept="application/pdf"
//                             required
//                             style={{
//                                 width: '100%',
//                                 padding: '10px',
//                                 borderRadius: '5px',
//                                 border: '1px solid #ccc',
//                                 marginTop: '5px',
//                                 color:"black",
//                                 backgroundColor: '#e6f7d7',
//                             }}
//                         />
//                     </div>
//                     <button
//                         type="submit"
//                         style={{
//                             width: '100%',
//                             padding: '12px',
//                             borderRadius: '5px',
//                             border: 'none',
//                             backgroundColor: '#4CAF50',
//                             color: '#fff',
//                             fontWeight: 'bold',
//                             cursor: 'pointer',
//                             transition: 'background-color 0.3s',
//                         }}
//                         onMouseOver={(e) => (e.target.style.backgroundColor = '#45a049')}
//                         onMouseOut={(e) => (e.target.style.backgroundColor = '#4CAF50')}
//                     >
//                         Upload
//                     </button>
//                 </form>
          
//                 {message && (
//                     <p style={{ marginTop: '20px', color: message.includes('successfully') ? 'green' : 'red' }}>
//                         {message}
//                     </p>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default POchecker;






import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import UserContext from "../../UserContext";
import DashboardHeader from '../Admin/Dashboard/DashboardHeader';
import './POChecker.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';


const POchecker = () => {
    const [formData, setFormData] = useState({
        location: '',
        division:'',
        dateOfOrder: '',
        poNumber: '',
        clientName: '',
        amount: '',
        orderNumber: '',
        username: ''
    });
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const { isAdmin, userDetails } = useContext(UserContext);
    const navigate = useNavigate();


    const redirectBack = () => {
        navigate(-1);
      }

    useEffect(() => {
        if (userDetails?.user.email) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                username: userDetails.user.email.split("@")[0] === 'admin' ? 'Irfan' : userDetails.user.email.split("@")[0],
            }));
        }
    }, [userDetails]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!file) {
            setMessage('Please upload a file.');
            return;
        }

        setIsUploading(true); // Start the upload animation
        const formPayload = new FormData();
        Object.keys(formData).forEach((key) => {
            formPayload.append(key, formData[key]);
        });
        formPayload.append('pdfFile', file);

        try {
            const response = await axios.post('https://apenterprise-server.onrender.com/po-details/', formPayload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setMessage(`File uploaded successfully! View Link: ${response.data.fileViewLink}`);
        } catch (error) {
            setMessage(error?.response?.data?.message || error.message);
            console.error(error);
        } finally {
            setIsUploading(false); // Stop the upload animation
        }
    };

    return (
        <div>
            <DashboardHeader />
            <div
                style={{
                    padding: '20px',
                    maxWidth: '600px',
                    margin: '50px auto',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    borderRadius: '10px',
                    backgroundColor: '#f9f9f9',
                }}
            >
                 <FontAwesomeIcon onClick={redirectBack} icon={faArrowLeftLong} size="2xl" className="back-icon" />
                <h1 style={{ textAlign: 'center', marginBottom: '20px', color: '#333', fontWeight: 'bold' }}>
                    Upload PO File
                </h1>
                <button
                    onClick={() => navigate('/view-po')} 
                    style={{
                        width: '20%',
                        marginTop: '20px',
                        padding: '12px',
                        borderRadius: '5px',
                        border: 'none',
                        backgroundColor: '#007BFF',
                        color: '#fff',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s',
                    }}
                    onMouseOver={(e) => (e.target.style.backgroundColor = '#0056b3')}
                    onMouseOut={(e) => (e.target.style.backgroundColor = '#007BFF')}
                >
                    View PO
                </button>
                <form onSubmit={handleSubmit}>
                    <div style={{ marginBottom: '15px', marginTop:'1em' }}>
                        <label style={{ fontWeight: 'bold', color: '#555' }}>Location:</label>
                        <input
                            type="text"
                            name="location"
                            value={formData.location}
                            onChange={handleChange}
                            required
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginTop: '5px',
                                color:"black",
                                backgroundColor: '#fff',
                            }}
                        />
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <label style={{ fontWeight: 'bold', color: '#555' }}>Date of Order:</label>
                        <input
                            type="date"
                            name="dateOfOrder"
                            value={formData.dateOfOrder}
                            onChange={handleChange}
                            required
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                color:"black",
                                marginTop: '5px',
                                backgroundColor: '#fff',
                            }}
                        />
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <label style={{ fontWeight: 'bold', color: '#555' }}>PO Number:</label>
                        <input
                            type="text"
                            name="poNumber"
                            value={formData.poNumber}
                            onChange={handleChange}
                            required
                            style={{
                                width: '100%',
                                padding: '10px',
                                color:"black",
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginTop: '5px',
                                backgroundColor: '#fff',
                            }}
                        />
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <label style={{ fontWeight: 'bold', color: '#555' }}>Division:</label>
                        <input
                            type="text"
                            name="division"
                            value={formData.division}
                            onChange={handleChange}
                            required
                            style={{
                                width: '100%',
                                padding: '10px',
                                color:"black",
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginTop: '5px',
                                backgroundColor: '#fff',
                            }}
                        />
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <label style={{ fontWeight: 'bold', color: '#555' }}>Client Name:</label>
                        <input
                            type="text"
                            name="clientName"
                            value={formData.clientName}
                            onChange={handleChange}
                            required
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '5px',
                                color:"black",
                                border: '1px solid #ccc',
                                marginTop: '5px',
                                backgroundColor: '#fff',
                            }}
                        />
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <label style={{ fontWeight: 'bold', color: '#555' }}>Amount:</label>
                        <input
                            type="number"
                            name="amount"
                            value={formData.amount}
                            onChange={handleChange}
                            required
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '5px',
                                color:"black",
                                border: '1px solid #ccc',
                                marginTop: '5px',
                                backgroundColor: '#fff',
                            }}
                        />
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <label style={{ fontWeight: 'bold', color: '#555' }}>Order Number:</label>
                        <input
                            type="text"
                            name="orderNumber"
                            value={formData.orderNumber}
                            onChange={handleChange}
                            required
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginTop: '5px',
                                color:"black",
                                backgroundColor: '#fff',
                            }}
                        />
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <label style={{ fontWeight: 'bold', color: '#555' }}>File:</label>
                        <input
                            type="file"
                            onChange={handleFileChange}
                            accept="application/pdf"
                            required
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginTop: '5px',
                                color:"black",
                                backgroundColor: '#e6f7d7',
                            }}
                        />
                    </div>
                    <button
                        type="submit"
                        style={{
                            width: '100%',
                            padding: '12px',
                            borderRadius: '5px',
                            border: 'none',
                            backgroundColor: '#4CAF50',
                            color: '#fff',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s',
                        }}
                        onMouseOver={(e) => (e.target.style.backgroundColor = '#45a049')}
                        onMouseOut={(e) => (e.target.style.backgroundColor = '#4CAF50')}
                    >
                        Upload
                    </button>
                </form>
                {isUploading && (
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <div className="loader" style={{
                            border: '4px solid #f3f3f3',
                            borderTop: '4px solid #3498db',
                            borderRadius: '50%',
                            width: '40px',
                            height: '40px',
                            animation: 'spin 1s linear infinite',
                            margin: '0 auto'
                        }}></div>
                        <p style={{ color: '#555', marginTop: '10px' }}>Uploading...</p>
                    </div>
                )}
                {message && (
                    <p style={{ marginTop: '20px', color: message.includes('successfully') ? 'green' : 'red' }}>
                        {message}
                    </p>
                )}
            </div>
        </div>
    );
};

export default POchecker;





