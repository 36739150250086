


import React, { useState } from 'react';
import './Header.css';
import logo from './AP_Logo.png';
import { NavLink, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavLinkClick = (path) => {
    if (location.pathname !== path) {
      toggleMenu();
    }
  };

  return (
    <div className='head'>
      <div className='head-timing'>
        <div className='head-timing-content'>
          <div className='head-time'>
            <p><i className="fa-sharp fa-regular fa-clock fa-beat fa-sm"> </i> </p>
            <div className='timing-div'>
            <p className='left-space'>MON-SAT: 9AM to 6PM</p>
            <p className='left-space'>SUN: CLOSED</p>
            </div>
           
           
          </div>
          <div className='hidden'>spaces</div>
        </div>
      </div>
      
      <div className='head-lists'>
        <div className='logo-container'>
          <img className='logo' width="230px" src={logo} alt="logo"/>
          <div className='hamburger' onClick={toggleMenu}>
            <FontAwesomeIcon icon={isOpen ? faTimes : faBars} size="lg" />
          </div>
        </div>

        <div className={`overlay ${isOpen ? 'open' : ''}`}></div>

        <ul className={`head-lists-items ${isOpen ? 'open' : ''}`}>
          <li className='list-item'>
            <NavLink className="link-react" to='/' onClick={() => handleNavLinkClick('/')}>Home</NavLink>
          </li>
          <li className='list-item'>
            <NavLink className="link-react" to='/team' onClick={() => handleNavLinkClick('/team')}>Team</NavLink>
          </li>
          <li className='list-item'>
            <NavLink className="link-react" to='/services' onClick={() => handleNavLinkClick('/services')}>Services</NavLink>
          </li>
          <li className='list-item'>
            <NavLink className="link-react" to='/login' onClick={() => handleNavLinkClick('/login')}>Login</NavLink> 
            <FontAwesomeIcon icon={faRightToBracket} />
          </li>
        </ul> 
      </div>
    </div>
  );
};

export default Header;
