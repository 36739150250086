import './Invoice.css';
import { initializeApp } from "firebase/app";
import { collection, getFirestore, limit, orderBy, query, setDoc, doc, serverTimestamp } from "firebase/firestore";
import { useContext, useEffect, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { db } from '../../firebase';
import { auth } from '../../firebase';
import InvoiceGrid from './InvoiceGrid';
import UserContext from '../../UserContext';
import { useNavigate } from 'react-router-dom';
import DashboardHeader from '../Admin/Dashboard/DashboardHeader';
import GenerateCLCBill from './GenerateCLCBill';

function Invoice() {
    const { logout, userDetails, authenticated, isAdmin } = useContext(UserContext);
    const navigate = useNavigate();
    const invoiceRef = collection(db, "invoices");

    const q = query(invoiceRef, orderBy("createdAt", "desc"), limit(10));
    const [invoicesData] = useCollectionData(q, { idField: 'id' });

    const [invoices, setInvoices] = useState([]);
    const [invNumber, setInvNumber] = useState(null);
    const [username, setUser] = useState("");
    const [companyName, setCompany] = useState("");
    const [Invdate, setDate] = useState("");
    const [invAmount, setInvAmount] = useState("");  // New state for invoice amount

    useEffect(() => {
        if (invoicesData) {
            const sortedInvoices = invoicesData.sort((a, b) => parseInt(b.invoiceNo, 10) - parseInt(a.invoiceNo, 10));
            setInvoices(sortedInvoices);
        }
    }, [invoicesData]);

    useEffect(() => {
        const username = userDetails.user.email.split('@')[0];
        if (username === 'admin') {
            setUser('Irfan');
        } else {
            setUser(username);
        }

        if (!isAdmin && invoices && invoices.length > 0) {
            const maxInvoiceNo = Math.max(...invoices.map(invoice => parseInt(invoice.invoiceNo, 10) || 0));
            setInvNumber(maxInvoiceNo + 1);
        } else {
            setInvNumber();
        }
    }, [username, invoices]);

    const sendInvoice = async () => {
        try {
            if (!invNumber || !username || !companyName || !Invdate || !invAmount) {
                alert("Complete the bill details before submitting..");
            } else {
                await setDoc(doc(invoiceRef), {
                    invoiceNo: invNumber,
                    user: username,
                    date: Invdate,
                    company: companyName,
                    amount: invAmount, // Add invoice amount here
                    createdAt: serverTimestamp(),
                });
                setCompany("");
                setUser("");
                setDate("");
                setInvAmount(""); // Reset invoice amount after submission
            }
        } catch (err) {
            console.log(err);
            alert("An error occurred..");
        }
    }

    return (
        <div className="app">
            <DashboardHeader />
            <header>
                <h3>Generate New Bill</h3>
            </header>
            <div style={{display:"flex",justifyContent:"center",marginBottom:"1em"}}> {isAdmin && <GenerateCLCBill invoiceRef={invoiceRef} invoices={invoices} />}</div>
           
            <div className='bill-info'>
                <div className='bill-info-bar'>
                    <label>INVOICE NO:</label>
                    {!isAdmin ? (
                        <input
                            style={{ backgroundColor: "#ebe4e4", color: "black", fontWeight: "800" }}
                            value={invNumber || ''}
                            readOnly
                            type="number"
                        />
                    ) : (
                        <input
                            style={{ backgroundColor: "#ebe4e4", color: "black", fontWeight: "800" }}
                            value={invNumber}
                            onChange={(e) => setInvNumber(e.target.value)}
                            type="text"
                        />
                    )}
                </div>
                <div className='bill-info-bar'>
                    <label>DATE:</label>
                    <input
                        style={{ backgroundColor: "#ebe4e4", color: "black", fontWeight: "800" }}
                        onChange={(e) => setDate(e.target.value)}
                        type="date"
                        value={Invdate}
                    />
                </div>
                <div className='bill-info-bar'>
                    <label>CONSIGNEE:</label>
                    <input
                        style={{ backgroundColor: "#ebe4e4", color: "black", fontWeight: "800" }}
                        value={companyName}
                        onChange={(e) => setCompany(e.target.value)}
                        type="text"
                    />
                </div>
                <div className='bill-info-bar'>  {/* New input field for invoice amount */}
                    <label>AMOUNT:</label>
                    <input
                        style={{ backgroundColor: "#ebe4e4", color: "black", fontWeight: "800" }}
                        value={invAmount}
                        onChange={(e) => setInvAmount(e.target.value)}
                        type="number"
                        step="0.01"  // Allow decimal values
                    />
                </div>
                <button className='button mobile-but' onClick={sendInvoice}>Submit</button>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <h3 style={{ color: "#4a90e2" }}>Last 10 Invoices</h3>
            </div>
            <div className='bill-details'>
                <div className='bill-box'>
                    <div className='bill-label'>
                        <h3>INVOICE NO</h3>
                        <h3>DATE</h3>
                        <h3>CONSIGNEE</h3>
                        <h3>USER NAME</h3>
                    </div>
                    <div className='invoice-detail'>
                        {invoices && invoices.map(invoice => <InvoiceGrid key={invoice.id} invoices={invoice} />)}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Invoice;

