import React, { useEffect, useState } from "react";
import DashboardHeader from "../Dashboard/DashboardHeader";
import { Pagination } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faGear, faXmark } from "@fortawesome/free-solid-svg-icons";
import './Payroll.css'
import axios from "axios";

const Payroll = () => {

    const navigate = useNavigate();

    const navigateToNewEntry = (id) => {
        navigate(`/newentry/${id}`)
    }

    const navigateToTransactions = (id) => {
      navigate(`/transactions/${id}`)
  }

    const navigateToCreatePayee = () => {
      navigate('/create-payee');
    }

    const navigateToEditPayee = (id) => {
      navigate(`/edit-payee/${id}`)
    }



 
    const [users, setUsers] = useState([]);
    const [loading,setLoadng] = useState(true);
    const [progress, setProgress] = useState(0);


    const getPayees = async () => {
      try {
        const resp = await axios.get('https://apenterprise-server.onrender.com/payee');
        setUsers(resp.data);
        setInterval(()=> {
          setLoadng(false);
        },2000)
       
      } catch(err){
        console.log(err);
      }
    }


    const searchPayees = async (e) => {
      try {
        var keyword = e;
        const resp = await axios.get('https://apenterprise-server.onrender.com/payee/search', {
          params: {
            keyword: keyword
          }
        })

        setUsers(resp.data);
      } catch(err){
        console.log(err);
      }
    }


    useEffect(()=> {
      getPayees();
      
      const simulateLoading = setInterval(() => {
        if (progress >= 100) {
          clearInterval(simulateLoading);
          return;
        }
        setProgress((prev) => prev + 2);
      }, 100);
  
      return () => clearInterval(simulateLoading);
    },[]);


    const [currentPage, setCurrentPage] = useState(1);
    const usersPerPage = 9;

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

    
      // Calculate total number of pages
      const totalPages = Math.ceil(users.length / usersPerPage);

      // Change page
      const paginate = pageNumber => setCurrentPage(pageNumber);
  
      // Calculate page numbers to display
      const pageNumbers = [];
      for (let i = Math.max(1, currentPage - 1); i <= Math.min(totalPages, currentPage + 1); i++) {
          pageNumbers.push(i);
      }


      const formatDate = (dateString) => {

        if(dateString){
          const date = new Date(dateString);
        
        const options = {
            day: 'numeric',
            month: 'long', 
            year: 'numeric'
        };
    
        return date.toLocaleDateString('en-GB', options);
        }else {
          return undefined;
        }
        
    };
    



  return (
    <div>
      <DashboardHeader />
      {!loading ? (
        <div className="container-fluid">
        <div className="row d-flex justify-content-center mt-3 mb-3">
        <div className="col-12 d-flex  h4 justify-content-center">
          <h4 >Payment list</h4>
          <button onClick={getPayees} class="btn btn-warning ms-5" type="button">Refresh<FontAwesomeIcon className="ms-2" icon={faArrowsRotate} /></button>
          </div>
          <div className="col-12 d-flex justify-content-center grid gap-0 column-gap-3"> <input onChange={(e) => searchPayees(e.target.value)} type="text" class="form-control border border-primary w-50 " placeholder="Search an entity.."/>
          <button class="btn btn-outline-secondary" type="button">Search</button> 
          <button onClick={navigateToCreatePayee} class="btn btn-info" type="button">Create</button>
          </div>
         
      
      </div>
         
          {currentUsers.length !== 0 ?  (<div className=" d-flex justify-content-center container flex-wrap grid gap-0 row-gap-2">
              {currentUsers.map((user,index) => 
                  (
                      <div key={index} class="card col-sm col-lg-4">
                      <div><img src={user.photoUrl} class="card-img-top img-fluid w-50 mx-auto d-block " alt="..."></img></div>
                        
                      <div class="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 class="card-title">{user.name}</h5>
                        <FontAwesomeIcon onClick={() => navigateToEditPayee(user._id)} className="settings-icon" icon={faGear} rotation={90} style={{color: "#74C0FC", }} size="lg"/>
                           </div>
                        <h6 class="card-subtitle mb-2 text-body-secondary">
                          {user.designation}
                        </h6>
                        
                        <p class="card-text ">
                          {user.name === 'Babu Khan' ? "Rent" : "Monthly Salary"} : Rs {user.salary.toLocaleString()}
                        </p>
                      
                        
                        <p class="card-text">
                          Total Outstanding : Rs {(user.salary - user.totalPaidThisMonth).toLocaleString()}
                        </p>
                        <p class="card-text">
                          Last Payment : {formatDate(user?.lastPayment)} 
                        </p>
                       
                          <button type="button" style={{ fontSize: "0.8rem" }} className="btn btn-primary m-1 btn-xs" onClick={()=> navigateToNewEntry(user._id)}>
                          New Entry
                          </button>
                        
          
                          <button type="button" style={{ fontSize: "0.8rem" }} onClick={()=> navigateToTransactions(user._id)} className="btn btn-success m-1 btn-xs">
                          Transaction History
                          </button>
                      </div>
                    </div>
                  )
              )}
          
          </div>) : (<div className="container d-flex justify-content-center mt-5"> <h3>No Payee with that name <FontAwesomeIcon icon={faXmark} size="lg" style={{color: "#ee1b50",}} /></h3> </div>)}
  
          <div className="d-flex justify-content-center mt-3">
                      <Pagination>
                      {pageNumbers.map(number => (
                              <Pagination.Item key={number} onClick={() => paginate(number)}>
                                  {number}
                              </Pagination.Item>
                          ))}
                      </Pagination>
                  </div>
  
  
  
        </div>
      ):
      (<div className="d-flex justify-content-center align-items-center" style={{height:"80vh"}}>
        <h5 className="m-2 ">Loading Content</h5>
        <div className="progress-bar w-50 ">
      <div className="progress" style={{ width: `${progress}%` }}></div>
    </div></div>
      )
      //((<div style={{height:"80vh"}} className="d-flex justify-content-center"><div className="loading-circle"></div></div>))
      }
      
    </div>
  );
};

export default Payroll;
