import "./App.css";
import React, { useState, useEffect } from 'react';
import Home from "./components/Home/Home";
import {  BrowserRouter as Router, Route, Routes,Navigate } from "react-router-dom";
import Services from "./components/Services/Services";
import Login from "./components/Login/Login";
import Invoice from "./components/Invoice/Invoice";
import UserProvider from "./UserProvider";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "./components/Admin/Dashboard/Dashboard";
import Payroll from "./components/Admin/Payroll/Payroll";
import NewEntry from "./components/Admin/Payroll/NewEntry";
import CreatePayee from "./components/Admin/Payroll/CreatePayee";
import EditPayee from "./components/Admin/Payroll/EditPayee";
import TransactionHistory from "./components/Admin/Payroll/TransactionHistory";
import CustomerPayments from "./components/Admin/CustomerPayments/CustomerPayments";
import CreateCustomer from "./components/Admin/CustomerPayments/CreateCustomer";
import PdfReader from "./components/Admin/CustomerPayments/PdfReader";
import UploadStatement from "./components/Admin/CustomerPayments/UploadStatement";
import PaymentHistory from "./components/Admin/CustomerPayments/PaymentHistory";
import CustomerNewEntry from "./components/Admin/CustomerPayments/CustomerNewEntry";
import IDCard from "./components/IdCard/IDCard";
import PriceList from "./components/PriceList/PriceList";
import BillDetails from "./components/BillDetails/BillDetails";
import AssignWork from "./components/AssignWork/AssignWork";
import LowStockTodoList from "./components/PriceList/LowStockToDoList";
import Projects from "./components/Projects/Projects";
import Employees from "./components/Employees/Employees";
import POchecker from "./components/POchecker/POchecker";
import POlisting from "./components/POchecker/POlisting";
import PODetails from "./components/POchecker/PODetails";
import POExpenseLabourList from "./components/POchecker/POExpenseLabourList";
import POExpenseOtherList from "./components/POchecker/POExpenseOtherList";
import ProfitManagement from "./components/ProfitManagement/ProfitManagement";
import ExpenseList from "./components/ProfitManagement/ExpenseList";


function App() {
  return (
    <UserProvider> 
    <Routes>
    <Route exact path="/" element={<Home />} />
    <Route exact path="/services" element={<Services />} />
    {/* <Route exact path="/projects" element={<Projects />} /> */}
    <Route exact path="/login" element={<Login />} />
    <Route exact path="/team" element={<Employees />} />
    <Route exact path="/employee-detail/:id" element={<IDCard/>} />
    <Route element={<PrivateRoute />}>
            <Route path="/invoice" element={<Invoice />} />
            <Route path="/admin-dashboard" element={<Dashboard />} />
            <Route path="/payroll" element={<Payroll />} />
            <Route path="/customers" element={<CustomerPayments />} />
            <Route path="/newentry/:id" element={<NewEntry />} />
            <Route path="/searchInvoice" element={<BillDetails />} />
            <Route path='/get-total-expenses' element={<ExpenseList/>}/>
            <Route path='/profit-management' element={<ProfitManagement/>}/>
            <Route path='/purchase-order' element={<POchecker/>}/>
            <Route path='/po-details/:id' element={<PODetails/>}/>
            <Route path='/labour-expense-list/:id' element={<POExpenseLabourList/>}/>
            <Route path='/other-expense-list/:id' element={<POExpenseOtherList/>}/>
            <Route path="/pricelist" element={<PriceList/>} />
            <Route path="/customer-newentry/:id" element={<CustomerNewEntry />} />
            <Route path="/create-payee" element={<CreatePayee />} />
            <Route path="/create-customer" element={<CreateCustomer />} />
            <Route path="/upload/:id" element={<UploadStatement />} />
            <Route path="/pricelist/low-stock-items" element={<LowStockTodoList />} />
            <Route path="/transactions/:id" element={<TransactionHistory />} />
            <Route path="/assignwork" element={<AssignWork />} />
            <Route path='view-po' element={<POlisting/>} />
            <Route path="/customer-transactions/:id" element={<PaymentHistory />} />
            <Route path="/edit-payee/:id" element={<EditPayee />} />
            
          </Route>
  </Routes>
  </UserProvider>   
  );
}

export default App;
