import React, { useEffect } from "react";
import { Pie } from "react-chartjs-2"; // Import Pie from react-chartjs-2
import "chart.js/auto";
import "./PieChart.css";

const PieChart = ({ expenses }) => {
  // Calculate total labour expenses
  const totalLabour = expenses?.reduce((sum, expense) => {
    return (
      sum +
      expense.labourExpenses.reduce(
        (labourSum, labour) => labourSum + Number(labour.totalAmount || 0),
        0
      )
    );
  }, 0);

  // Calculate total other expenses
  const totalOther = expenses?.reduce((sum, expense) => {
    return (
      sum +
      expense.otherExpenses.reduce(
        (otherSum, other) => otherSum + Number(other.amount || 0),
        0
      )
    );
  }, 0);

  // Calculate total amount (if you have an order amount, otherwise it's derived from the sums)
  const totalOrderAmount = expenses?.reduce((sum, expense) => {
    return (
      sum +
      expense.labourExpenses.reduce(
        (labourSum, labour) => labourSum + Number(labour.totalAmount || 0),
        0
      ) +
      expense.otherExpenses.reduce(
        (otherSum, other) => otherSum + Number(other.amount || 0),
        0
      )
    );
  }, 0);

  // Calculate profit or loss
  let profit = totalOrderAmount - totalLabour - totalOther;

  // Chart Data
  const chartData = {
    labels: ["Labour Expenses", "Other Expenses"],
    datasets: [
      {
        data: [totalLabour, totalOther],
        backgroundColor: ["#FF6384", "#36A2EB"],
      },
    ],
  };


  return (
    <div className="pie-expense-container">
      <div className="chart-container">
        <h3 className="section-title">Expense Distribution</h3>
        <Pie className="pie-diagram" data={chartData} />
      </div>
      <h4 className="pie-expenses-title">Total Labour Expenses: ₹ {totalLabour}</h4>
      <h4 className="pie-expenses-title">Total Other Expenses: ₹ {totalOther}</h4>
      <h4 className="pie-expenses-title">Total Order Amount: ₹ {totalOrderAmount}</h4>
      <h4 className="pie-expenses-title">
        {profit > 0
          ? `Profit Amount: ₹ ${profit}`
          : profit < 0
          ? `Loss Amount: ₹ ${Math.abs(profit)}`
          : ``}
      </h4>
    </div>
  );
};

export default PieChart;
