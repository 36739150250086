import React, { useEffect, useState } from "react";
import axios from "axios";
import DashboardHeader from "../Admin/Dashboard/DashboardHeader";
import POExpense from "../POchecker/POExpense";
import PieChart from "../POchecker/PieChart";
import ProfitExpense from "./ProfitExpense";
import './ExpenseList.css';
import { useNavigate } from "react-router-dom";

const ProfitManagement = () => {

  const [expenses,setExpenses] = useState();

  useEffect(()=> {
    const getExpenses = async () => {
      const response = await axios.get(
        "https://apenterprise-server.onrender.com/po-details/get-expenses"
      );
      setExpenses(response.data);
    }

    getExpenses();
  },[]);

  return (
    <div>
        <DashboardHeader/>
        <div className="profit-management-div">
          <div className="profit-expense-div">
          <ProfitExpense/>
          </div>
          <div className="profit-pie-div">
          <PieChart expenses={expenses}/>
          </div>
       
        </div>
    </div>
  )
}


export default ProfitManagement