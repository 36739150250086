import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import logo from '../Header/AP_Logo.png'
import { NavLink } from 'react-router-dom'


const IDCard = () => {

    const {id} = useParams();
    const [userData,setUserData] = useState();

    const getPersonDetails = async () => {
        try{
            const resp = await axios.get(`https://apenterprise-server.onrender.com/payee/get-payee/${id}`);
            setUserData(resp.data);
        } catch(err){
          console.log(err);
            alert("Error fetching Data");
        }
    }



    useEffect(()=> {
        getPersonDetails();
    },[]);


  return (
    <div>
      <nav class="navbar bg-body-tertiary d-flex">
        <div class="container-fluid">
        <NavLink className="link-react navbar-brand mb-0 h1 mx-3" to='/'><img className='logo' width="180px" src={logo} alt="logo"/></NavLink>
          {/* <span class="navbar-brand mb-0 h1 mx-3"> <img className='logo' width="230px" src={logo} alt="logo"/></span> */}
          <p style={{fontWeight:"800"}}>Employer Phone Number:  +91 8017915682</p>
          <p style={{fontWeight:"800"}}>Office Address : Shop B-52, Dalmia Shopping Complex, Pin Code - 743330 </p>
        </div>
      </nav>
    <div className="container d-flex justify-content-center mt-3 mb-5">
    <div class="card" style={{width: "20rem"}}>
        <img src={userData?.photoUrl} class="card-img-top" alt="Profile Picture" />
        <div class="card-body">
          <h5 style={{fontWeight:"800"}} class="card-title">{userData?.name}</h5>
          <p class="card-text">
           {userData?.designation}
          </p>
        </div>
        <ul class="list-group list-group-flush">
          <li  class="list-group-item">Phone number: <span style={{fontWeight:"800"}}>{userData?.phoneNumber} </span> </li>
          <li  class="list-group-item">Address: <span style={{fontWeight:"800"}}>{userData?.address} </span> </li>
          <li  class="list-group-item">Employee Id: <span style={{fontWeight:"800"}}>{userData?._id.substring(0,7)} </span> </li>
        </ul>
      </div>
    </div>
      
    </div>
  );
};

export default IDCard;
