import React, { useEffect, useState } from "react";
import axios from "axios";
import DashboardHeader from "../Admin/Dashboard/DashboardHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const POlisting = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filterQuery, setFilterQuery] = useState("");
  const [amount, setAmount] = useState("");
  const [poDetails, setPoDetails] = useState([]);
  const [message, setMessage] = useState("");
  const [totalDetails, setTotalDetails] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("all");
  const itemsPerPage = 5;

  const navigate = useNavigate();

  const redirectBack = () => {
    navigate(-1);
  };


  const handleFilterChange = async (filterType) => {
    setFilter(filterType);
    setMessage("");
    setPoDetails([]);

    let endpoint = "";
    switch (filterType) {
        case "all":
        endpoint = "/get-all-bills";
        break;
      case "paid":
        endpoint = "/get-paid-bills";
        break;
      case "unpaid":
        endpoint = "/get-unpaid-bills";
        break;
      case "completed":
        endpoint = "/get-completed-pos";
        break;
      case "incomplete":
        endpoint = "/get-incompleted-pos";
        break;
      default:
        return;
    }

    try {
      const response = await axios.get(
        `https://apenterprise-server.onrender.com/po-details/${endpoint}`
      );

    //   const search = (filterType!=='completed' && filterType!=='incomplete'&& filterType!=='all' && filterType!=='paid' && filterType!=='unpaid') ? 'search' : filterType;

    //   console.log(search,"-",searchQuery);

      const amountResponse = await axios.get(
        `https://apenterprise-server.onrender.com/po-details/get-total-amount?queryType=${filterType}&searchField=${searchQuery}`
      );
      if (response.data.length > 0) {
        setPoDetails(response.data);
        setAmount(amountResponse.data.totalAmount);
        setTotalDetails(response.data.length);
      } else {
        setMessage(`No ${filterType} POs found.`);
      }
    } catch (error) {
      setMessage(
        error.response?.data?.message ||
          "An error occurred while filtering POs."
      );
      console.error(error);
    }
  };


  useEffect(()=> {
   handleFilterChange('all');
  },[])

  const handleSearchQuery = async (e) => {
    e.preventDefault();
    setMessage("");
    setPoDetails([]);

    if (!filterQuery.trim()) {
      setMessage("Please enter a search query.");
      return;
    }

    try {
      const response = await axios.get(
        `https://apenterprise-server.onrender.com/po-details/search`,
        {
          params: { query: filterQuery },
        }
      );

      const amountResponse = await axios.get(
        `https://apenterprise-server.onrender.com/po-details/get-total-amount?queryType=search&searchField=${searchQuery}`
      );
      if (response.data.length > 0) {
        setPoDetails(response.data);
        setAmount(amountResponse.data.totalAmount)
        setTotalDetails(response.data.length)
      } else {
        setMessage("No results found for the given search query.");
      }
    } catch (error) {
      setMessage(
        error.response?.data?.message || "An error occurred while searching."
      );
      console.error(error);
    }
  };

  const handlePageChange = (direction) => {
    if (direction === "next" && currentPage * itemsPerPage < poDetails.length) {
      setCurrentPage((prev) => prev + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const redirectToDetails = (id) => {
    navigate(`/po-details/${id}`);
  };

  const markAsPaid = async (id) => {
    const confirmPayment = window.confirm("Are you sure you want to mark this as PAID?");
  
    if (!confirmPayment) {
      return; 
    }




    try {
      const response = await axios.put(
        `https://apenterprise-server.onrender.com/po-details/payment-done/${id}`
      );
      const updatedDetails = poDetails.map((po) =>
        po.poNumber === id ? { ...po, paymentRecieved: true } : po
      );
      setPoDetails(updatedDetails);
      alert("Payment marked as received.");
    } catch (error) {
      console.error("Error marking as paid:", error);
      alert("Failed to mark payment as received.");
    }
  };


  const markAsClaimed = async (id) => {
    const confirmPayment = window.confirm("Are you sure you want to mark this as CLAIMED?");
  
    if (!confirmPayment) {
      return; 
    }

    try {
      const response = await axios.put(
        `https://apenterprise-server.onrender.com/po-details/security-claimed/${id}`
      );
      const updatedDetails = poDetails.map((po) =>
        po.poNumber === id ? { ...po, isSecurityClaimed: true } : po
      );
      setPoDetails(updatedDetails);
      alert("Security Claimed for this PO.");
    } catch (error) {
      console.error("Error marking as paid:", error);
      alert("Failed to mark Security as claimed.");
    }
  };



  const handleSearchChange = (e) => {
    setFilterQuery(e.target.value);
    setSearchQuery(e.target.value);
  }


  const paginatedDetails = poDetails.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div>
      <DashboardHeader />
      <div
        style={{
          padding: "20px",
          maxWidth: "600px",
          margin: "50px auto",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <FontAwesomeIcon
          onClick={redirectBack}
          icon={faArrowLeftLong}
          size="2xl"
          className="back-icon"
        />
        <h1
          style={{
            textAlign: "center",
            marginBottom: "20px",
            color: "#333",
            fontWeight: "bold",
          }}
        >
          Search POs
        </h1>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
            <button
            onClick={() => handleFilterChange("all")}
            style={{
              padding: "10px",
              borderRadius: "5px",
              backgroundColor: filter === "all" ? "#007BFF" : "#5bc1fc",
              color: "#fff",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            All
          </button>
          <button
            onClick={() => handleFilterChange("paid")}
            style={{
              padding: "10px",
              borderRadius: "5px",
              backgroundColor: filter === "paid" ? "#007BFF" : "#5bc1fc",
              color: "#fff",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            Paid
          </button>
          <button
            onClick={() => handleFilterChange("unpaid")}
            style={{
              padding: "10px",
              borderRadius: "5px",
              backgroundColor: filter === "unpaid" ? "#007BFF" : "#5bc1fc",
              color: "#fff",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
              Unpaid
          </button>
          {/* <button
            onClick={() => handleFilterChange("completed")}
            style={{
              padding: "10px",
              borderRadius: "5px",
              backgroundColor: filter === "completed" ? "#007BFF" : "#ccc",
              color: "#fff",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            Unpaid/Completed
          </button> */}
          <button
            onClick={() => handleFilterChange("incomplete")}
            style={{
              padding: "10px",
              borderRadius: "5px",
              backgroundColor: filter === "incomplete" ? "#007BFF" : "#5bc1fc",
              color: "#fff",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            Incomplete
          </button>
        </div>
        <form onSubmit={handleSearchQuery} style={{ marginBottom: "20px" }}>
          <div style={{ marginBottom: "15px" }}>
            <label style={{ fontWeight: "bold", color: "#555" }}>
              Search (PO Number, Location, Division or Client Name):
            </label>
            <input
              type="text"
              value={filterQuery}
            //   onChange={(e) => setFilterQuery(e.target.value)}
            onChange={(e)=> handleSearchChange(e)}
              required
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                marginTop: "5px",
                backgroundColor: "#fff",
                color: "black",
              }}
            />
          </div>
          <button
            type="submit"
            style={{
              width: "100%",
              padding: "12px",
              borderRadius: "5px",
              border: "none",
              backgroundColor: "#4CAF50",
              color: "#fff",
              fontWeight: "bold",
              cursor: "pointer",
              transition: "background-color 0.3s",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#45a049")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#4CAF50")}
          >
            Search
          </button>
        </form>
        {message && (
          <p style={{ marginTop: "20px", color: "red", textAlign: "center" }}>
            {message}
          </p>
        )}
        {paginatedDetails.length > 0 && (
          <div
            style={{
              marginTop: "20px",
              padding: "15px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              backgroundColor: "#f5f5f5",
            }}
          >
            <h3 style={{ marginBottom: "10px", color: "#333" }}>
              Search Results: {totalDetails}
            </h3>
            {amount !== null && (
              <h4
                style={{
                  color: "#007BFF",
                  fontWeight: "bold",
                  borderBottom: "1px solid grey",
                  paddingBottom: "0.6em",
                }}
              >
                Total Amount: ₹{" "}
                {new Intl.NumberFormat("en-IN", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(parseFloat(amount))}
              </h4>
            )}
            {paginatedDetails.map((po) => (
              <div
                key={po._id}
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr auto",
                  columnGap: "15px",
                  alignItems: "center",
                  borderBottom: "1px solid grey",
                  marginBottom: "1em",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    rowGap: "10px",
                    columnGap: "15px",
                    marginBottom: "1em",
                  }}
                >
                  <span>
                    <strong>PO:</strong> {po.poNumber}
                  </span>
                  <span>
                    <strong>Location:</strong> {po.location}
                  </span>
                  <span>
                    <strong>Division:</strong> {po.division}
                  </span>
                  <span>
                    <strong>Client:</strong> {po.clientName}
                  </span>
                  <span>
                    <strong>Amount:</strong> ₹{po.amount}
                  </span>
                  <span>
                    <strong>Order:</strong> {po.orderNumber}
                  </span>
                  <span>
                    <strong>By:</strong> {po.createdBy}
                  </span>
                  <span
                    style={{
                      color: po.isBillSubmitted ? "#28a745" : "#dc3545",
                      fontWeight: "bold",
                    }}
                  >
                    Bill Submitted: {po.isBillSubmitted ? "✔ Yes" : "✘ No"}
                  </span>
                  <span
                    style={{
                      color: po.paymentRecieved ? "#28a745" : "#dc3545",
                      fontWeight: "bold",
                    }}
                  >
                    Payment Received: {po.paymentRecieved ? "✔ Yes" : "✘ No"}
                  </span>
                  {po.paymentRecieved &&    <span
                    style={{
                      color: po.isSecurityClaimed ? "#28a745" : "#dc3545",
                      fontWeight: "bold",
                    }}
                  >
                    Security Claimed: {po.isSecurityClaimed ? "✔ Yes" : "✘ No"}
                  </span>}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <button
                    onClick={() => redirectToDetails(po.poNumber)}
                    style={{
                      padding: "8px 12px",
                      borderRadius: "5px",
                      border: "1px solid #007BFF",
                      backgroundColor: "#007BFF",
                      color: "#fff",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  >
                    More Details
                  </button>
                  {!po.paymentRecieved && po.isBillSubmitted && (
                    <button
                      onClick={() => markAsPaid(po.poNumber)}
                      style={{
                        padding: "8px 12px",
                        borderRadius: "5px",
                        border: "1px solid #28a745",
                        backgroundColor: "#28a745",
                        color: "#fff",
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                    >
                      Mark as Paid
                    </button>
                  )}
                {po.paymentRecieved && !po?.isSecurityClaimed && (
                    <button
                      onClick={() => markAsClaimed(po.poNumber)}
                      style={{
                        padding: "8px 12px",
                        borderRadius: "5px",
                        border: "1px solid rgb(189, 168, 36)",
                        backgroundColor: "rgb(205, 182, 33)",
                        color: "#fff",
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                    >
                      Security Claimed
                    </button>
                  )}

                </div>
              </div>
            ))}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "15px",
              }}
            >
              <button
                onClick={() => handlePageChange("prev")}
                disabled={currentPage === 1}
                style={{
                  padding: "8px 15px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  backgroundColor: currentPage === 1 ? "#f0f0f0" : "#4CAF50",
                  color: currentPage === 1 ? "#999" : "#fff",
                  fontWeight: "bold",
                  cursor: currentPage === 1 ? "not-allowed" : "pointer",
                  transition: "background-color 0.3s",
                }}
              >
                Previous
              </button>
              <button
                onClick={() => handlePageChange("next")}
                disabled={currentPage * itemsPerPage >= poDetails.length}
                style={{
                  padding: "8px 15px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  backgroundColor:
                    currentPage * itemsPerPage >= poDetails.length
                      ? "#f0f0f0"
                      : "#4CAF50",
                  color:
                    currentPage * itemsPerPage >= poDetails.length
                      ? "#999"
                      : "#fff",
                  fontWeight: "bold",
                  cursor:
                    currentPage * itemsPerPage >= poDetails.length
                      ? "not-allowed"
                      : "pointer",
                  transition: "background-color 0.3s",
                }}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default POlisting;

