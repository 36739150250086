import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Employees.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import defaultImage from './employee.png'; // Import default image

const Employees = () => {
  const [employeeData, setEmployeeData] = useState([]); // State to store all team data (employees + founders)
  const [loading, setLoading] = useState(true); // State to handle loading status

  // Array of founder IDs
  const founderIds = ['6704e3582a0393ca4130dd65', '6704d92a2a0393ca4130dd49'];

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        // Fetch all employees
        const employeesResponse = await axios.get('https://apenterprise-server.onrender.com/payee');
        
        // Filter out employees who are founders
        const nonFounderEmployees = employeesResponse.data.filter(
          (employee) => !founderIds.includes(employee._id)
        );

        // Fetch founders individually
        const founder1Response = await axios.get('https://apenterprise-server.onrender.com/payee/get-payee/6704e3582a0393ca4130dd65');
        const founder2Response = await axios.get('https://apenterprise-server.onrender.com/payee/get-payee/6704d92a2a0393ca4130dd49');
        
        // Combine founders and non-founder employees
        const combinedData = [
          founder1Response.data,
          founder2Response.data,
          ...nonFounderEmployees
        ];

        // Update state with combined data
        setEmployeeData(combinedData);
      } catch (error) {
        console.error("Error fetching team data:", error);
      } finally {
        setLoading(false); // Set loading to false after all data is fetched
      }
    };

    fetchTeamData();
  }, []);

  return (
    <div>
      <Header />
      <div className="employees-container">
        <h2 className="employees-title">Team Members</h2>

        <div className="employees-grid">
          {loading
            ? Array.from({ length: 6 }).map((_, index) => (
                <div key={index} className="employee-card skeleton-card">
                  <div className="skeleton-image"></div>
                  <div className="skeleton-text skeleton-name"></div>
                  <div className="skeleton-text skeleton-designation"></div>
                </div>
              ))
            : employeeData.map((employee) => (
                <div key={employee._id} className="employee-card">
                  <img
                    src={employee.photoUrl || defaultImage} // Use default image if photoUrl is not available
                    alt={employee.name}
                    className="employee-image"
                  />
                  <h3 className="employee-name">{employee.name}</h3>
                  <p className="employee-designation">{employee.designation}</p>
                </div>
              ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Employees;
